import { Box, Heading } from 'grommet'

import React, { FC } from 'react'

const Home: FC = () => (
  <Box justify="center" align="center" fill direction="column">
    <Heading textAlign="center">Watch Party</Heading>
  </Box>
)
export default Home
